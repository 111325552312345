import { render, staticRenderFns } from "./photograph.vue?vue&type=template&id=13a75568&scoped=true"
import script from "./photograph.vue?vue&type=script&lang=js"
export * from "./photograph.vue?vue&type=script&lang=js"
import style0 from "./photograph.vue?vue&type=style&index=0&id=13a75568&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a75568",
  null
  
)

export default component.exports