<template>
  <div v-if="isShow === 'photograph'">
    <van-checkbox
      v-model="checked"
      checked-color="#ee0a24"
      class="van--checkbox"
      v-if="checkboxShow === 'block'"
      @click="setCheckboxAll()"
    >
      全选
    </van-checkbox>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="text"
      @load="onLoad"
    >
      <div class="grid-list">
        <div
          class="img-box"
          v-for="(item, index) in list"
          :key="item.id"
          @click="preview(index)"
        >
          <img :src="item.cover_url" alt="" />
          <div
            class="icon-checkbox"
            :class="{ 'checkbox-back': item.isSelect === 1 }"
            :style="{ display: checkboxShow }"
            @click="checkboxFn(item.id, index)"
            @click.stop
          >
            <van-icon
              name="success"
              size="16"
              color="#fff"
              class="icon-success"
              :style="{ display: isSelect }"
            />
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { delAlbumFile, getAlbumList } from "@/api/album";
import { Dialog, Toast } from "vant";
import { ImagePreview } from "vant";
export default {
  name: "photograph",
  components: {},
  props: {
    isShow: {},
    checkboxShow: {
      type: String,
      default: "none",
    },
  },
  data() {
    return {
      text: "没有更多了",
      loading: false,
      finished: false,
      checkId: 0,
      checked: false,
      isSelect: "", // 复选框控制显示 none是隐藏 block是显示
      imgList: [],
      list: [],
      isAjax: false,
      total: 100,
      isBlank: false,
      photoStatus: {
        q_id: "",
        file_type: 1,
        page: 1,
      },
    };
  },
  computed: {},
  watch: {
    isShow(newVal, oldVal) {
      if (newVal === "photograph") {
        this.checked = false;
        this.startOrderList();
      }
    },
  },
  mounted() {},
  methods: {
    onLoad() {
      // console.log("---onLoad--page---------", this.photoStatus.page);
      this.getAlbumList("updateList");
    },
    delPhoto() {
      let ids = [];
      let list_num = this.list.length;
      for (let i = 0; i < list_num; i++) {
        if (this.list[i].isSelect === 1) {
          ids[ids.length] = this.list[i].id;
        }
      }
      if (ids.length <= 0) {
        Toast.fail("请选择需要删除的照片");
        return false;
      }
      Dialog.confirm({
        title: "提示",
        message: "你确定取消此照片吗？",
      })
        .then(() => {
          this.delAlbumFile(ids);
        })
        .catch(() => {
          // on cancel
        });
    },
    preview(index) {
      let images = [];
      this.list.forEach((item) => {
        images.push(item.cover_url);
      });
      ImagePreview({
        images: images,
        startPosition: index,
      });
    },
    async delAlbumFile(ids) {
      // console.log("------delAlbumFile-----------", ids);
      const ret = await delAlbumFile({ file_ids: ids });
      if (ret.code * 1 === 1) {
        Toast.success("删除成功");
        this.checked = false;
        this.startOrderList();
        this.list.forEach((value, key) => {
          if (value.isSelect === 1) {
            this.list.splice(key, 1);
          }
        });
        if (this.list.length <= 0) {
          this.$emit("closeEdit");
        }
      } else {
        Toast.fail(ret.msg);
      }
    },
    setCheckboxAll() {
      let isSelect = this.checked === true ? 1 : 0;
      let list_num = this.list.length;
      for (let i = 0; i < list_num; i++) {
        this.list[i].isSelect = isSelect;
      }
    },
    checkboxFn(id, index) {
      this.list[index].isSelect = this.list[index].isSelect === 1 ? 0 : 1;
      this.isCheckboxAll();
    },
    isCheckboxAll() {
      let checked = true;
      let list_num = this.list.length;
      for (let i = 0; i < list_num; i++) {
        if (this.list[i].isSelect === 0) {
          checked = false;
          break;
        }
      }
      this.checked = checked;
    },
    startOrderList() {
      //初始化订单列表
      this.photoStatus.page = 1;
      this.list = [];
      this.total = 100;
      // console.log("---this.photoStatus---------", this.photoStatus.page);
      this.getAlbumList();
    },
    async getAlbumList(str) {
      //获取列表数据
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      this.finished = false;
      const ret = await getAlbumList(this.photoStatus);
      this.checked = false;
      // console.log("----云相册-----", ret);
      if (ret.data.total <= 0) {
        this.text = "";
        this.$emit("showBlank", true);
      } else {
        this.text = "没有更多了";
        this.$emit("showBlank", false);
      }
      if (ret.code * 1 == 1) {
        ++this.photoStatus.page;
        let list_num = ret.data.items.length;
        if (list_num >= 0) {
          for (let i = 0; i < list_num; i++) {
            ret.data.items[i].isSelect = 0;
          }
        }
        if (str === "updateList") {
          this.list = [...this.list, ...ret.data.items];
        } else {
          this.list = ret.data.items;
        }
        this.total = ret.data.total;
      }
      this.loading = false;
      if (this.list.length >= this.total) {
        this.finished = true;
        this.photoStatus.page = 1;
      }
      this.setCheckboxAll();
      this.isAjax = false;
    },
    cancel() {
      this.checked = false;
      this.setCheckboxAll();
    },
  },
};
</script>

<style lang="less" scoped>
.grid-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px;
}
.img-box {
  position: relative;
  width: 189px;
  height: 189px;
  margin-bottom: 12px;
  overflow: hidden;
  border-radius: 10px;
  .icon-checkbox {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.7019607843137254);
    border-radius: 50%;
  }
  .icon-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .checkbox-back {
    border: 1px solid #ffffff;
    background: linear-gradient(148deg, #f8757e 0%, #f51e07 100%);
    opacity: 1;
  }
  img {
    height: 189px;
    width: 189px;
  }
}
.van--checkbox {
  padding: 12px 12px 0 12px;
  font-size: 16px;
}
</style>
