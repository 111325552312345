<template>
  <div class="sadhsahhjas"  >
   <div   v-if="isWx"  id="wx">
     <van-uploader
       :after-read="afterRead"
       accept="*.mp4"
     />
   </div>
    <div   v-else >
      <van-uploader
        :after-read="afterRead"
        accept="*.mp4,*.AVI,*.FLV,*.MP4,*.MPG,*.ASF,*.WMV,*.MOV,*.WMA,*.RMVB,*.RM,*.FLASH,*.TS"
      />
    </div>

    <van-overlay :show="overlay">
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-loading size="40px" type="spinner" vertical>
            <div class="title-text">正在上传</div>
            <div class="num-text">{{ num }}%</div>
          </van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import oss from "ali-oss";
import { getAliSts} from "@/api/common";
import { Toast } from "vant";
import tools from "@/common/js/tools";
import $ from 'jquery';

export default {
  name: "oss-video",
  data() {
    return {
      aliOss: "",
      overlay: false,
      num: 0,
      fileList: [],
      imgArr: [],
      actions: [{ name: "拍照" }, { name: "从手机里面选择" }],
      isWx: false,
    };
  },
  async mounted() {
    let ret = await getAliSts({});
    if (ret.code * 1 === 1) {
      this.aliOss = new oss({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: "oss-cn-chengdu",
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: ret.data.AccessKeyId,
        accessKeySecret: ret.data.AccessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: ret.data.SecurityToken,
        refreshSTSToken: async () => {
          // 向您搭建的STS服务获取临时访问凭证。
          let ret = await getAliSts({});
          if (ret.code * 1 === 1) {
            return {
              accessKeyId: ret.info.accessKeyId,
              accessKeySecret: ret.info.accessKeySecret,
              stsToken: ret.info.stsToken,
            };
          } else {
            Toast.fail(ret.msg);
          }
        },
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: 800000,
        // 填写Bucket名称。
        bucket: "jhnewshop",
      });
    } else {
      Toast.fail(ret.msg);
    }
    this.isWx = tools.isWx();
    console.log(this.isWx )
    if (this.isWx) {
    // let fileObj=$('#wx').find("input[type=file]");
    //   console.log(fileObj)
    }
  },
  methods: {
    openFile(){
      console.log('---------------------------------')
      if(!this.isWx){
        return false;
      }
      let _that = this;
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          let localIds = res.localIds[0]; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          _that.sendFile(localIds)
        },
      });
    },
    async resumeUpload() {
      let tempCheckpoint;
      try {
        const result = await this.aliOss.multipartUpload(
          "exampleobject.txt",
          file,
          {
            progress: function (p, checkpoint) {
              tempCheckpoint = checkpoint;
            },
            checkpoint: tempCheckpoint,
            meta: { year: 2020, people: "test" },
            mime: "text/plain",
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    getTime(file, url) {
      let video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      let _that = this;
      video.addEventListener("durationchange", function (e) {
        let duration = video.duration;
        let hour = parseInt(duration / 3600);
        let minute = parseInt((duration % 3600) / 60);
        if (minute < 10) {
          minute = "0" + minute;
        }
        let sec = parseInt(duration % 60);
        if (sec < 10) {
          sec = "0" + sec;
        }
        let time_str = minute + ":" + sec;
        if (hour * 1 > 0) {
          if (hour < 10) {
            hour = "0" + hour;
          }
          time_str = hour + ":" + time_str;
        }
        setTimeout(function () {
          _that.$emit("getImages", { url: url, time: time_str });
        }, 300);
      });
    },
     afterRead(object) {
      let file = object.file;
      this.sendFile(file)
    },
    async sendFile(file){
      if (this.aliOss === "") {
        Toast.fail("上传主键正在初始化中....");
        return false;
      }
      let path = this.getFilPathName(file);
      let  fileFormat = path.substring(path.lastIndexOf(".")).toLowerCase();

      if( !fileFormat.match(/\.mp4|\.avi|\.flv|\.mpg|\.asf|\.wmv|\.mov|\.wma|\.rmvb|\.rm|\.flash|\.ts/) ) {
        Toast.fail('视频格式错误');
        return;
      }
      let tempCheckpoint;
      try {
        let _that = this;
        const result = await this.aliOss.multipartUpload(path, file, {
          progress: function (p, checkpoint) {
            // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
            _that.overlay = true;
            tempCheckpoint = checkpoint;
            _that.num = (p * 100).toFixed(2);
            if (p >= 1) {
              _that.overlay = false;
              //上传完成，拼接地址
              let url = "https://jhnewshop.oss-cn-chengdu.aliyuncs.com/" + path;
              Toast.success("上传成功");
              _that.getTime(file, url);
            }
          },
          meta: { year: 2020, people: "test" },
          mime: "text/plain",
        });
      } catch (e) {
        console.log(e);
      }
    },
    getFilPathName(file) {
      let date = new Date();
      let path =
        "user/" +
        date.getYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        "/" +
        date.getMilliseconds();
      path += file.name;
      return path;
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 168px;
  background-color: #fff;
  border-radius: 6px;
}
.van-loading__text {
  text-align: center;
}
.title-text {
  font-size: 18px;
  color: #000;
  margin: 16px 0 0 7px;
}
.num-text {
  font-size: 21px;
  color: #000;
}
</style>
