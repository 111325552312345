<template>
  <div class="aaa">
    <!--
    <video :src="videoSrc" :poster="videoImg" :autoplay="playStatus" height="421" width="700" :muted="muteStatus">
      your browser does not support the video tag
    </video>
    <button @click="playClick" :class="{hide: isPlay}">点击播放</button> -->
    <!--class="video-js vjs-default-skin vjs-big-play-centered" -->
    <div class="video-cover" v-show="!showVideo" @click="playVideo">
      <img :src="videoImg" class="video-img" />
      <div class="cover-box">
        <van-icon name="play-circle-o" size="30" color="#fff" />
      </div>
    </div>
    <video
      id="videoObj"
      ref="videoObj"
      v-show="showVideo"
      :preload="preload"
      :poster="videoImg"
      :height="setHeight"
      :width="setWidth"
      align="center"
      :controls="controls"
      autoplay="false"
      muted
      @pause="stopVideo"
    >
      <!--      :autoplay="autoplay"-->
      <!--  静音    muted-->
      <!--  循环播放    loop-->
      <!--           controlsList='nofullscreen nodownload'-->
      <source :src="videoSrc" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "video-play",
  props: {
    url: {
      default: "",
    },
    controls: {
      default: true,
    },
    isPlay: {
      default: false,
    },
    autoplay: {
      default: "",
    },
    setWidth: {
      default: "",
    },
    setHeight: {
      default: "",
    },
  },
  data() {
    return {
      showVideo: false,
      play: true,
      videoSrc:
        "https://jhnewshop.oss-cn-chengdu.aliyuncs.com/user/121-11-15/5142ef3bd543f558cc51e598d3992ff63cf.mp4",
      videoImg:
        "https://jhnewshop.oss-cn-chengdu.aliyuncs.com/user/121-11-15/5142ef3bd543f558cc51e598d3992ff63cf.mp4?x-oss-process=video/snapshot,t_7000,f_jpg,w_600,h_0,m_fast",
      playStatus: "",
      muteStatus: "",
      isMute: true,
      // isPlay: false,
      width: "auto", // 设置视频播放器的显示宽度（以像素为单位）
      height: "auto", // 设置视频播放器的显示高度（以像素为单位）
      preload: "auto", //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      // controls: false, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      // autoplay: 'autoplay',
    };
  },
  mounted() {
    // if (this.setWidth !== "") {
    //   this.width = this.setWidth;
    // } else {
    //   this.width = document.body.clientWidth;
    // }
    console.log("--------this.width---------" + this.setWidth);
    // if (this.setHeight !== "") {
    //   this.height = this.setHeight;
    // }
    console.log("----this.height-------------" + this.setHeight);
    // this.height = document.body.clientHeight;
    if (this.url !== "") {
      this.videoSrc = this.url;
      console.log(this.videoSrc);
      this.videoImg =
        this.url +
        "?x-oss-process=video/snapshot,t_7000,f_jpg,w_600,h_0,m_fast";
    }
  },
  watch: {
    showVideo: function () {
      console.log("-----------------sadsa");
    },
  },
  methods: {
    playVideo() {
      console.log("---------------------");
      this.showVideo = true;
      this.$refs.videoObj.play();
      // let audio = document.getElementById('videoObj');
      // console.log(audio)
      // audio.play();
    },
    stopVideo() {
      console.log("---------------结束了");
      if (this.showVideo) {
        this.showVideo = false;
      }
      this.$refs.videoObj.pause();
    },
  },
};
</script>

<style lang="less" scoped>
.video-cover {
  position: relative;
  width: 100%;
  height: 100%;
  .cover-img {
    width: 100%;
    height: 100%;
  }
  .cover-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.video-img {
  width: 100%;
  height: 100%;
}
</style>
