<template>
  <div :class="{ 'back-color': isBlank }">
    <!-- nav -->
    <nav class="navigation">
      <van-icon
        name="arrow-left"
        size="20"
        color="#9B9B9B"
        class="close-icon"
        @click="lastStep"
      />
      <span class="nav-title">{{ active === 1 ? "云相册" : "云视频" }}</span>
    </nav>
    <div class="content">
      <!-- tab-bar -->
      <div class="tabs">
        <div :class="{ 'black-color': true }"></div>
        <div class="tab">
          <span
            class="tab-photograph"
            :class="{ 'tab-color': active === 1 }"
            @click="onClick(1)"
          >
            照片
          </span>
          <span
            class="tab-video"
            :class="{ 'tab-color': active === 2 }"
            @click="onClick(2)"
          >
            视频
          </span>
        </div>
        <div class="tab-button">
          <div class="tab-button" v-if="isButton">
            <div class="edit-btn">
              <van-button block round plain @click="editFn">编辑</van-button>
            </div>
            <div class="uploading-btn">
              <van-button block round plain type="danger" @click="uploadingFn">
                {{ tabShow === "photograph" ? "上传照片" : "上传视频" }}
              </van-button>
            </div>
          </div>
          <div class="tab-button" v-else>
            <div class="edit-btn">
              <van-button block round plain @click="delFile">删除</van-button>
            </div>
            <div class="uploading-btn">
              <van-button block round plain type="danger" @click="cancel">
                取消
              </van-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <Photograph
        ref="Photograph"
        :is-show="tabShow"
        :checkbox-show="checkboxShow"
        @showBlank="showBlank"
        @closeEdit="closeEdit"
      ></Photograph>
      <Video
        ref="Video"
        :is-show="tabShow"
        :checkbox-show="checkboxShow"
        @showBlank="showBlank"
        @closeEdit="closeEdit"
      ></Video>
    </div>
    <!-- blank -->
    <BlankPage :blank="blank" v-if="isBlank"></BlankPage>
    <div class="upload-pictures" v-if="isBlank" @click="uploadingFn">
      <!-- {{ bankTitle }} -->
      {{ tabShow === "photograph" ? "上传照片" : "上传视频" }}
    </div>
    <UploadingImg
      v-if="uploadingImg"
      @closeImgUploading="closeImgUploading"
    ></UploadingImg>
    <UploadingVideo
      v-if="uploadingVideo"
      @closeVideoUploading="closeVideoUploading"
    ></UploadingVideo>
  </div>
</template>

<script>
import BlankPage from "./components/blank-page/blank-page";
import Photograph from "./moudles/photograph/photograph";
import Video from "./moudles/video/video.vue";
import UploadingImg from "./components/uploading-img/uploading-img";
import UploadingVideo from "./components/uploading-video/uploading-video";
import { Toast } from "vant";

export default {
  name: "cloud-photo",
  components: {
    BlankPage,
    Photograph,
    Video,
    UploadingImg,
    UploadingVideo,
  },
  data() {
    return {
      bankTitle: "上传照片",
      active: 1,
      isButton: true,
      tabShow: "photograph",
      isBlank: false,
      checkboxShow: "none",
      uploadingImg: false,
      uploadingVideo: false,
      blank: {
        imgUrl: require("@/assets/blank/collect-blank-images@2x.png"),
        text: "你还没上传这个资源",
      },
    };
  },
  mounted() {},
  methods: {
    closeEdit() {
      this.isButton = true;
      this.isEdit();
    },
    delFile() {
      if (this.active === 1) {
        this.$refs.Photograph.delPhoto();
      } else {
        this.$refs.Video.delPhoto();
      }
    },
    showBlank(bool) {
      this.isBlank = bool;
    },
    editFn() {
      let listNUm = 0;
      if (this.active === 1) {
        listNUm = this.$refs.Photograph.list.length;
      } else {
        listNUm = this.$refs.Video.list.length;
      }
      if (listNUm <= 0) {
        Toast.fail("暂无可编辑的数据");
        return false;
      }
      //编辑按钮
      this.isButton = false;
      this.isEdit();
    },
    uploadingFn() {
      //去上传
      if (this.tabShow === "photograph") {
        this.uploadingImg = true;
      } else {
        this.uploadingVideo = true;
      }
    },
    closeImgUploading(type) {
      // 图片上传关闭
      this.isBlank = false;
      this.uploadingImg = false;
      if (type === true) {
        //重新加载数据
        this.$refs.Photograph.startOrderList();
      }
    },
    closeVideoUploading(type) {
      // 视频上传关闭
      this.isBlank = false;
      this.uploadingVideo = false;
      if (type === true) {
        //重新加载数据
        this.$refs.Video.startOrderList();
      }
    },
    cancel() {
      // 取消按钮
      this.isButton = true;
      this.isEdit();
      this.$refs.Photograph.cancel();
      this.$refs.Video.cancel();
    },
    onClick(num) {
      // tab切换
      this.isButton = true;
      this.checkboxShow = "none";
      this.active = num;
      if (num === 1) {
        this.isBlank = false;
        this.tabShow = "photograph";
      } else {
        this.isBlank = false;
        this.tabShow = "videoShow";
      }
    },
    isEdit() {
      // 是否为编辑状态
      if (this.checkboxShow === "block") {
        this.checkboxShow = "none";
      } else {
        this.checkboxShow = "block";
      }
    },
    lastStep() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.back-color {
  background-color: #fff;
  min-height: 100vh;
}

.upload-pictures {
  position: absolute;
  top: 59%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 206px;
  height: 52px;
  border-radius: 52px;
  color: #fff;
  border: 1px solid #ed1d4e;
  text-align: center;
  line-height: 52px;
  font-size: 17px;
  background: linear-gradient(to right, #fe5876, #ed301d);
  box-shadow: 0px 2px 4px rgba(199, 8, 8, 0.26);
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 58px;
  text-align: center;
  background-color: #fff;
  z-index: 2000;

  .close-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  .nav-title {
    font-size: 21px;
    line-height: 58px;
  }
}

.content {
  margin-top: 58px;
}

.tabs {
  display: flex;
  height: 52px;
  padding: 0 12px;
  // background-color: #fff;
  .black-color {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    height: 238px;
    background: linear-gradient(to bottom, #ffffff, #ffffff, #f9f9f9);
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    font-size: 18px;
    color: #aaaaaa;
  }

  .tab-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    .edit-btn {
      width: 66px;
    }

    .uploading-btn {
      width: 90px;
      margin-left: 14px;
    }
  }
}

.van-button {
  height: 34px;
}

.van-button--default {
  border: 1px solid #6f6f6f;
}

.van-button__text {
  font-size: 17px;
}

.van-button--normal {
  padding: 0;
}

.tab-color {
  color: #000;
  font-weight: 500;
}
</style>
