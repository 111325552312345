<template>
  <van-popup
    v-model="isShow"
    :close-on-click-overlay="false"
    duration="0"
    :overlay="false"
  >
    <nav class="navigation">
      <van-icon
        name="arrow-left"
        size="20px"
        color="#9B9B9B"
        class="last-step"
        @click="lastStep"
      />
      <span class="nav-title">发布视频</span>
      <div class="publish" @click="addAlbumFile">发布</div>
    </nav>
    <div class="images-content">
      <div class="return-show" v-for="(item, index) in imgs" :key="index">
        <div class="colse-icon">
          <van-icon name="cross" color="#fff" @click="delVideoCover(index)" />
        </div>
        <img
          class="video-img"
          :src="
            item.url +
            '?x-oss-process=video/snapshot,t_7000,f_jpg,w_600,h_0,m_fast'
          "
        />
        <span class="video-time">{{ item.time }}</span>
      </div>
      <OssVideo @getImages="getImages"></OssVideo>
    </div>
  </van-popup>
</template>

<script>
import OssVideo from "@/components/oss/oss-video";
import { Toast } from "vant";
import { addAlbumFile } from "@/api/album";

export default {
  name: "uploading-img",
  components: {
    OssVideo,
  },

  data() {
    return {
      isShow: true,
      overlay: false,
      imgs: [],
    };
  },
  methods: {
    delVideoCover(ind) {
      this.imgs.map((item, index) => {
        if (index === ind) {
          this.imgs.splice(index, 1);
        }
      });
      console.log(this.imgs);
    },
    async addAlbumFile() {
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      if (this.imgs.length <= 0) {
        Toast.fail("请上传视频");
        return false;
      }
      let ret = await addAlbumFile({ file_type: 2, file_url: this.imgs });
      if (ret.code * 1 === 1) {
        Toast.success("发布成功");
        let _that = this;
        setTimeout(function () {
          _that.$emit("closeVideoUploading", true);
        }, 100);
      } else {
        Toast.fail(ret.msg);
        this.isAjax = false;
      }
    },
    getImages(imgArr) {
      this.imgs.push(imgArr);
      console.log("imgArr-----------", this.imgs);
    },
    onPublish() {
      // 发布
      this.overlay = true;
    },
    lastStep() {
      this.imgs = [];
      this.$emit("closeVideoUploading", true);
    },
  },
};
</script>

<style lang="less" scoped>
.navigation {
  position: relative;
  height: 58px;
  font-size: 21px;
  background-color: #fff;
  text-align: center;

  .last-step {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  .nav-title {
    line-height: 58px;
  }

  .publish {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: #e61f18;
  }
}

.van-popup--center {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 0, 0);
}

.images-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  padding: 17px;
  background-color: #fff;
}

.van-popup {
  background-color: #f5f5f5;
}

/deep/ .van-uploader__upload {
  width: 120px;
  height: 120px;
  margin: 3px;
}

/deep/ .van-uploader__preview-image {
  width: 120px;
  height: 120px;
}

/deep/ .van-uploader__preview {
  margin: 3px;
}

/deep/ .van-uploader {
  width: 100%;
}

/deep/ .van-uploader__wrapper {
  width: 100%;
}

.return-show {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 3px;

  .colse-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.22);
    border: 1px solid rgba(255, 255, 255, 0.7019607843137254);
    border-radius: 50%;
    opacity: 0.81;
  }

  .video-time {
    display: block;
    position: absolute;
    bottom: 5px;
    right: 8px;
    width: 38px;
    font-size: 14px;
    color: #ffffff;
  }
}
.video-img {
  width: 120px;
  height: 120px;
}
</style>
