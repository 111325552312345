import request from './ajax'
/**
 * 闲置模块接口
 */
const usedUrl = '/api/album/'
// 上传图片或者视频
export const addAlbumFile = (data) => request(usedUrl + 'add_file', 'post', { ...data })
//获取列表
export const getAlbumList = (data) => request(usedUrl + 'list', 'post', { ...data })
//删除
export const delAlbumFile = (data) => request(usedUrl + 'album_del', 'post', { ...data })

