<template>
  <van-popup
    v-model="isShow"
    :close-on-click-overlay="false"
    duration="0"
    :overlay="false"
  >
    <nav class="navigation">
      <van-icon
        name="arrow-left"
        size="20px"
        color="#9B9B9B"
        class="last-step"
        @click="lastStep"
      />
      <span class="nav-title">发布照片</span>
      <div class="publish" @click="addAlbumFile">发布</div>
    </nav>
    <div class="images-content">
      <OssImg @getImages="getImages" :emptyImg="emptyImg"></OssImg>
    </div>
    <van-overlay :show="overlay">
      <div class="wrapper" @click.stop>
        <div class="block">
          <van-loading size="40px" type="spinner" vertical>
            <div class="title-text">正在上传</div>
            <div class="num-text">{{ "2" }}/{{ "9" }}</div>
          </van-loading>
        </div>
      </div>
    </van-overlay>
  </van-popup>
</template>

<script>
import OssImg from "@/components/oss/oss-img";
import { addAlbumFile } from "@/api/album";
import { Toast } from "vant";
export default {
  name: "uploading-img",
  components: {
    OssImg,
  },

  data() {
    return {
      isShow: true,
      overlay: false,
      imgs: [],
      isAjax: false,
      emptyImg: [],
    };
  },
  methods: {
    async addAlbumFile() {
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      if (this.imgs.length <= 0) {
        Toast.fail("请上传图片");
        return false;
      }
      let ret = await addAlbumFile({ file_type: 1, file_url: this.imgs });
      if (ret.code * 1 === 1) {
        Toast.success("发布成功");
        let _that = this;
        setTimeout(function () {
          _that.$emit("closeImgUploading", true);
        }, 100);
      } else {
        Toast.fail(ret.msg);
        this.isAjax = false;
      }
    },
    getImages(imgArr) {
      this.imgs = imgArr;
    },
    lastStep() {
      this.emptyImg = [];
      this.$emit("closeImgUploading", true);
    },
  },
};
</script>

<style lang="less" scoped>
.navigation {
  position: relative;
  height: 58px;
  font-size: 21px;
  background-color: #fff;
  text-align: center;
  .last-step {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
  .nav-title {
    line-height: 58px;
  }
  .publish {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: #e61f18;
  }
}
.van-popup--center {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 0, 0);
}
.images-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  padding: 17px;
  background-color: #fff;
}
.van-popup {
  background-color: #f5f5f5;
}
/deep/.van-uploader__upload {
  width: 120px;
  height: 120px;
  margin: 3px;
}
/deep/.van-uploader__preview-image {
  width: 120px;
  height: 120px;
}
/deep/ .van-uploader__preview {
  margin: 3px;
}
/deep/.van-uploader {
  width: 100%;
}
/deep/.van-uploader__wrapper {
  width: 100%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 168px;
  background-color: #fff;
  border-radius: 6px;
}
.van-loading__text {
  text-align: center;
}
.title-text {
  font-size: 18px;
  color: #000;
  margin: 16px 0 0 7px;
}
.num-text {
  font-size: 21px;
  color: #000;
}
</style>
