<template>
  <div v-if="isShow === 'videoShow'">
    <van-checkbox
      v-model="checked"
      checked-color="#ee0a24"
      class="van--checkbox"
      v-if="checkboxShow === 'block'"
      @click="setCheckboxAll()"
    >
      全选
    </van-checkbox>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="text"
      :immediate-check="false"
      @load="onLoad"
    >
      <div class="content">
        <div
          class="video-container"
          v-for="(item, index) in list"
          :key="item.id"
        >
          <div v-show="!item.isPlayer">
            <!--显示层-->
            <img :src="item.cover_url" alt="" />
            <div class="video-play" @click="showVideo(index)">
              <van-icon name="play" size="18" color="#FFFFFF" />
            </div>
          </div>
          <div v-show="item.isPlayer">
            <van-popup
              v-model="item.isPlayer"
              :closeable="true"
              :close-on-click-overlay="false"
              @click-close-icon="closeVideo"
            >
              <!--播放层-->
              <Player ref="videoServe" :url="item.file_url"></Player>
            </van-popup>
          </div>

          <div
            class="icon-checkbox"
            :class="{ 'checkbox-back': item.isSelect === 1 }"
            :style="{ display: checkboxShow }"
            @click="checkboxFn(item.id, index)"
            @click.stop
          >
            <van-icon
              name="success"
              size="16"
              color="#fff"
              class="icon-success"
              :style="{ display: isSelect }"
            />
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { delAlbumFile, getAlbumList } from "@/api/album";
import Player from "@/components/video/video";
import Cookies from "js-cookie";
export default {
  name: "videoShow",
  props: ["isShow", "checkboxShow"],
  components: {
    Player,
  },
  data() {
    return {
      text: "没有更多了",
      playVideo: false,
      checked: false,
      isSelect: "",
      imgList: [],
      list: [],
      isAjax: false,
      loading: false,
      finished: false,
      total: 100,
      videoIndex: 0,
      photoStatus: {
        m_id: Cookies.get("M_ID"),
        q_id: "",
        file_type: 2,
        page: 1,
      },
    };
  },
  watch: {
    isShow(newVal, oldVal) {
      if (newVal === "videoShow") {
        // console.log("-----------------newVal------------------------");
        this.checked = false;
        this.startOrderList();
      }
    },
  },
  mounted() {},
  methods: {
    onLoad() {
      // console.log("-----------------onLoad------------------------");
      this.getOrderList("updateList");
    },
    closeVideo() {
      this.list[this.videoIndex].isPlayer = 0;
      if (this.$refs.videoServe[this.videoIndex]) {
        this.$refs.videoServe[this.videoIndex].stopVideo();
      }
    },
    showVideo(index) {
      this.videoIndex = index;
      this.list.forEach((value, key) => {
        if (key !== index) {
          value.isPlayer = false;
        }
      });
      this.list[index].isPlayer = true;
    },
    delPhoto() {
      let ids = [];
      let list_num = this.list.length;
      for (let i = 0; i < list_num; i++) {
        if (this.list[i].isSelect === 1) {
          ids[ids.length] = this.list[i].id;
        }
      }
      if (ids.length <= 0) {
        Toast.fail("请选择需要的删除视频");
        return false;
      }
      Dialog.confirm({
        title: "提示",
        message: "你确定取消此视频吗？",
      })
        .then(() => {
          this.delAlbumFile(ids);
        })
        .catch(() => {
          // on cancel
        });
    },
    async delAlbumFile(ids) {
      const ret = await delAlbumFile({ file_ids: ids });
      if (ret.code * 1 === 1) {
        Toast.success("删除成功");
        this.startOrderList();
        this.list.forEach((value, key) => {
          if (value.isSelect === 1) {
            this.list.splice(key, 1);
          }
        });
        if (this.list.length <= 0) {
          this.$emit("closeEdit");
        }
      } else {
        Toast.fail(ret.msg);
      }
    },
    setCheckboxAll() {
      let isSelect = this.checked === true ? 1 : 0;
      let list_num = this.list.length;
      for (let i = 0; i < list_num; i++) {
        this.list[i].isSelect = isSelect;
      }
    },
    checkboxFn(id, index) {
      this.list[index].isSelect = this.list[index].isSelect === 1 ? 0 : 1;
      this.isCheckboxAll();
    },
    isCheckboxAll() {
      let checked = true;
      let list_num = this.list.length;
      for (let i = 0; i < list_num; i++) {
        if (this.list[i].isSelect === 0) {
          checked = false;
          break;
        }
      }
      this.checked = checked;
    },
    startOrderList() {
      //初始化订单列表
      this.list = [];
      this.photoStatus.page = 1;
      this.total = 100;
      this.getOrderList();
    },
    async getOrderList(str) {
      //获取订单列表数据
      if (this.isAjax) {
        return false;
      }

      this.isAjax = true;
      this.finished = false;
      const ret = await getAlbumList(this.photoStatus);
      console.log("--------视频列表---------", ret);
      if (ret.data.total <= 0) {
        this.text = "";
        this.$emit("showBlank", true);
      } else {
        this.text = "没有更多了";
        this.$emit("showBlank", false);
      }
      if (ret.code * 1 == 1) {
        ++this.photoStatus.page;
        this.checked = false;
        let list_num = ret.data.items.length;
        if (list_num >= 0) {
          for (let i = 0; i < list_num; i++) {
            ret.data.items[i].isSelect = 0;
            ret.data.items[i].isPlayer = false;
          }
        }
        if (str === "updateList") {
          this.list = [...this.list, ...ret.data.items];
        } else {
          this.list = ret.data.items;
        }
        this.total = ret.data.total;
      }
      this.loading = false;
      if (this.list.length >= this.total) {
        this.finished = true;
        this.photoStatus.page = 1;
      }
      this.setCheckboxAll();
      this.isAjax = false;
    },
    cancel() {
      this.checked = false;
      this.setCheckboxAll();
    },
  },
};
</script>

<style lang="less" scoped>
.van--checkbox {
  padding: 12px 12px 0 12px;
  font-size: 16px;
}

.content {
  padding: 0 12px;

  .video-container {
    position: relative;
    height: 206px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;

    .video-play {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 48px;
      height: 48px;
      background: rgba(0, 0, 0, 0.22);
      border: 1px solid #ffffff;
      opacity: 1;
      border-radius: 50%;
    }

    .icon-checkbox {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 20px;
      height: 20px;
      background: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.7019607843137254);
      border-radius: 50%;
    }

    .icon-success {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .checkbox-back {
      border: 1px solid #ffffff;
      background: linear-gradient(148deg, #f8757e 0%, #f51e07 100%);
      opacity: 1;
    }

    img {
      width: 100%;
      height: 206px;
    }
  }
}
.van-popup {
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  transform: translate3d(0, -50%, 0);
}
</style>
